<template>
    <div class='ad-project-manage'>
        <!-- <page-manage v-if='isActive === 3' :isActive.sync='isActive'></page-manage>
        <material-manage v-else-if='isActive === 4'  :isActive.sync='isActive'></material-manage> -->
        <router-view></router-view>
    </div>
</template>

<script>
// import pageManage from './page-manage'
// import materialManage from './material-manage'
import { mapMutations } from 'vuex'
export default {
  name: 'adProjectManage',
  props: ['isActive'],
  data () {
    return {

    }
  },
  watch: {
    isActive (newV) {
      //
    },
    $router: {
      handler (newV) {
      },
      immediate: true
    }
  },
  created () {
    this.setIsProjectSaved(false)
  },
  methods: {
    ...mapMutations(['setIsProjectSaved'])
  },
  components: {
    // pageManage,
    // materialManage

  }
}
</script>
<style lang="scss" scoped>
    .ad-project-manage{
      width:calc(100% - 200px)
    }
</style>
